import { match } from "ts-pattern";
import { classes } from "../../stylesheet.js";
import { make_stylesheet, DEFAULT_STYLES, DEFAULT_STYLEHSEET } from "#figma";
import {getTranslation, limitDecimals, px2vh, px2vw, translate} from "#utils";
import mocks from "#mocks";
import { Extras } from "../../types.js";
import type { Layouts, FC, Components } from "../../types.js";
import { signal } from "@preact/signals";
import { useEffect, useState } from "react";
import calculator from "./calculators/index.js";
import {  printPrice } from "../../utils.js";

const taxStatus = signal<'withTax' | 'withoutTax'>('withTax');
let totalPrice = 0;

const Footer: FC<{ stylesheet: ReturnType<typeof make_stylesheet<"cart">>, data: Parameters<Layouts.Cart>[0]["data"], templating: Parameters<Layouts.Cart>[0]["templating"], injected: Parameters<Layouts.Cart>[0]["injected"] }> = ({ stylesheet, data, injected, templating }) => {
    return (
        <div style={{
            ...classes('w-full', 'absolute', 'b-0'),
            height: `${px2vh(200)}vh`,
            boxShadow: '4px 0px 16px 0px #0863ec40',
            ...DEFAULT_STYLEHSEET.cart.default('section1'),
            ...stylesheet.default('section1')
        }}>
            {data.signals.cart.value[data.shop.token]?.length ?
                <div onClick={injected?.onClearCart} id={'empty-cart-button'} className={'navigable sales-buttons'} style={{
                    ...classes('text-center', 'rounded', 'absolute'),
                    left: `${px2vw(250)}vw`,
                    top: `${px2vh(72)}vh`,
                    bottom: `${px2vh(72)}vh`,
                    width: `${px2vw(600)}vw`,
                    height: `${px2vh(56)}vh`,
                    display:"inline-table",
                    backgroundColor: 'white',
                    paddingBottom: `calc(${px2vh(9)}vh - 0.2vw)`,
                    paddingTop: `calc(${px2vh(9)}vh - 0.2vw)`,
                    fontSize: `${px2vw(32)}vw`,
                    border: '1px solid #D3DAE1',
                    ...DEFAULT_STYLEHSEET.cart.default('buttons'),
                    ...stylesheet.default('buttons')
                }}>{translate(templating.texts, 'Empty cart')}</div>
                : null}
            {data.signals.cart.value[data.shop.token]?.length ?
                <div onClick={injected?.onSubmit} id={'submit-button'} className={'navigable sales-buttons'} tabIndex={0} style={{
                    ...classes('text-center', 'rounded', 'absolute'),
                    right: `${px2vw(250)}vw`,
                    top: `${px2vh(72)}vh`,
                    bottom: `${px2vh(72)}vh`,
                    width: `${px2vw(600)}vw`,
                    height: `${px2vh(56)}vh`,
                    backgroundColor: 'white',
                    display:"inline-table",
                    paddingBottom: `calc(${px2vh(9)}vh - 0.2vw)`,
                    paddingTop: `calc(${px2vh(9)}vh - 0.2vw)`,
                    fontSize: `${px2vw(32)}vw`,
                    border: '1px solid #D3DAE1',
                    ...DEFAULT_STYLEHSEET.cart.default('buttons'),
                    ...stylesheet.default('buttons')
                }}>{translate(templating.texts, 'Finalise your order')}</div>
                : null}
        </div>
    );
}

const CartEntry: FC<{ offset: number, data: Parameters<Layouts.Cart>[0]["data"], templating: Parameters<Layouts.Cart>[0]["templating"], entry: Parameters<Layouts.Cart>[0]["data"]["signals"]["cart"]["value"][string][number], stylesheet: ReturnType<typeof make_stylesheet<"cart">>, injected: NonNullable<Parameters<Layouts.Cart>[0]["injected"]> }> = ({ data, templating, entry, stylesheet, injected, offset }) => {
    const onItemClick = () => {
        sessionStorage.setItem("itemClick", `cart-entry-${offset}`);
        injected?.onItemClick?.();
    }
    const extraNamesToShow = Object.entries(entry.config || {}).length ? Object.entries(entry.config || {}).map(([extraId, extraConfig]) => {
        return match(data.extras[extraId])
            .with({ type: "combobox" }, (extra) => extra.options.choices.filter(choice => (extraConfig as Extras.Config.Combobox).includes(choice.id)).map(x => `x1 ${getTranslation(x.translations, templating.languageCode, templating.projectLanguageCode)}`))
            .with({ type: "multicombobox" }, (extra) => extra.options.choices.filter(choice => Object.keys(extraConfig as Extras.Config.Multicombobox).includes(choice.id)).map(x => `x${(extraConfig as Extras.Config.Multicombobox)[x.id]} ${getTranslation(x.translations, templating.languageCode, templating.projectLanguageCode)}`))
            .with({ type: "normal" }, (extra) => `x1 ${extra.name}`)
            .with({ type: "numeric" }, (extra) => `x${(extraConfig as Extras.Config.Numeric)} ${extra.name}`)
            .otherwise(() => 'Unknown extra');
    }) : [];
    const priceOfExtras = Object.entries(entry.config || {}).map(([extraId, extraConfig]) => [data.extras[extraId], extraConfig]).reduce((acc, cur) => {
        const prices = calculator(cur[0], cur[1]);
        return ({ priceWithTax: acc.priceWithTax + prices.priceWithTax, priceWithoutTax: acc.priceWithoutTax + prices.priceWithoutTax });
    }, { priceWithTax: 0, priceWithoutTax: 0 });
    const entryPrice = ((data.variants[entry.variant]!.price[taxStatus.value] + priceOfExtras[`priceW${taxStatus.value.slice(1)}` as 'priceWithTax' | 'priceWithoutTax']) / 100 * entry.quantity);
    totalPrice += entryPrice;

    return (
        <div onClick={() => { onItemClick() }} style={{ marginBottom: '8px', overflowY: 'visible', height: `${px2vh(153)}vh` }}>
            {data.shop.productsWithImages &&  data.variants[entry.variant]!.featuredAsset?
            <injected.Image alt="product image" src={data.variants[entry.variant]!.featuredAsset} style={{
                ...classes('rounded-sm', 'inline-block', 'rounded'),
                height: `${px2vh(144)}vh`,
                width: `${px2vw(173)}vw`,
                objectFit: 'cover',
            }} />: data.shop.productsWithImages ?
            <div class="rounded"  style={{
                display:"inline-block",
                height: `${px2vh(144)}vh`,
                width: `${px2vw(173)}vw`,
                backgroundColor: 'rgba(167, 181, 196,0.3)'
                }}
            />:null
        }

            <div id={`cart-entry-${offset}`} onFocus={(e)=>{e.target.scrollIntoView({ block: "center", behavior: "smooth" })}} data-pos={offset} data-variantId={entry.variant} data-productId={entry.productId} data-variantTimestamp={entry.timestamp} className={"navigable"} style={{
                ...classes('rounded', 'relative'),
                overflowY: 'visible',
                height: `${px2vh(144)}vh`,
                width: `${px2vw(data.shop.productsWithImages?1172: 1377)}vw`,
                marginRight: `${px2vw(40)}vw`,
                paddingTop: `${px2vh(30)}vh`,
                paddingLeft: `${px2vw(41)}vw`,
                float: 'right',
                ...DEFAULT_STYLEHSEET.cart.default('items'),
                ...stylesheet.default('items')
            }}>
                <div id={`cart-entry-${offset}-desc`} style={{ float: 'left' }}>
                    <div style={{ ...classes('bold'), fontSize: `${px2vw(30)}vw` }}>x{entry.quantity} {getTranslation(data.variants[entry.variant].translations, templating.languageCode, templating.projectLanguageCode)}</div>
                    <div style={{ fontSize: `${px2vw(28)}vw`, marginLeft: `${px2vw(50)}vw`, width: `${px2vw(700)}vw`, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{extraNamesToShow.flat().join(", ")}</div>
                </div>
                <div style={{ ...classes('text-center', 'absolute', 'bold'), top: `${px2vh(58)}vh`, right: `${px2vw(20)}vw` }}>
                    {printPrice(entryPrice,data.shop.currencyCode)}
                </div>
            </div>
        </div>
    );
}

const default_list: Components.List = ({ id, style = {}, children }) => <div id={id} style={{ ...style }} >{children}</div>;
const default_image: Components.Image = ({ id, style = {}, src, alt }) => <img id={id} alt={alt} src={src} style={{ ...style }} />;

const Main: FC<{ data: Parameters<Layouts.Cart>[0]["data"], templating: Parameters<Layouts.Cart>[0]["templating"], stylesheet: ReturnType<typeof make_stylesheet<"cart">>, injected: Parameters<Layouts.Cart>[0]["injected"], total: number }> = ({ data, templating, stylesheet, injected, total }) => {
    const List = injected?.List ?? default_list;
    const Image = injected?.Image ?? default_image;
    taxStatus.value = data.shop.pricesIncludeTax ? 'withTax' : 'withoutTax';
    totalPrice = 0;
    const list_elements_id = (data.signals.cart.value?.[data.shop.token] ?? []).map((x, i) =>`cart-entry-${i}`);
    list_elements_id.push('submit-button')
    
    return (
        <div style={{ ...classes('absolute', 'rounded'), boxSizing: 'content-box', overflow: 'hidden', width: `${px2vw(1458)}vw`, height: `${px2vh(747)}vh`, top: `${px2vh(64)}vh`, marginLeft: "auto", marginRight: "auto", background: 'rgba(184, 206, 215, 0.70)', display: 'block', position: 'relative', ...DEFAULT_STYLEHSEET.cart.default('section3'), ...stylesheet.default('section3') }}>
            <div className="title" style={{ ...classes('bold', 'absolute'), fontSize: `${px2vw(36)}vw`, top: `${px2vh(40)}vh`, left: `${px2vw(40)}vw`, textTransform: 'capitalize' }}>{translate(templating.texts, 'cart')}</div>
            {data.signals?.cart?.value[data.shop.token]?.length ?            
                <div id="wrapper-cart-list" style={{ overflowY:'scroll', marginTop: `${px2vh(100)}vh`, paddingTop: `${px2vh(8)}vh`, height: `${px2vh(521)}vh`, paddingLeft: `${px2vw(40)}vw`,}}>
                    <List id={"cart-list"} itemsIds={list_elements_id} style={{height: `${px2vh(521)}vh`}}>
                        {(data.signals.cart.value?.[data.shop.token] ?? []).map((x, i) => <CartEntry key={i} offset={i} data={data} templating={templating} entry={x} stylesheet={stylesheet} injected={{ ...injected, List, Image }} />)}
                    </List>
                </div>
                :
                <div style={{
                    marginTop: `${px2vh(100)}vh`,
                    paddingTop: `${px2vh(8)}vh`,
                    height: `${px2vh(521)}vh`,
                    overflowY: 'scroll',
                    paddingLeft: `${px2vw(40)}vw`,
                }}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>{translate(templating.texts, 'The cart is empty')}</div>
                </div>
            }

            <div style={{ ...classes('text-right', 'absolute'), fontSize: `${px2vw(24)}vw`, bottom: `${px2vh(61)}vh`, right: `${px2vw(79)}vw`, }}>{translate(templating.texts, data.shop.pricesIncludeTax ? 'Tax included' : 'Tax excluded')}</div>
            <div style={{ ...classes('bold', 'absolute'), fontSize: `${px2vw(36)}vw`, bottom: `${px2vh(17)}vh`, right: `${px2vw(79)}vw`, textTransform: 'capitalize' }}>{translate(templating.texts, 'total')} {printPrice(total, data.shop.currencyCode)}</div>
        </div>
    );
}

export default (function Cart({ data, signals, templating, injected }) {
    if (!data)
        data = mocks.cart(templating.languageCode);

    const stylesheet = make_stylesheet(templating.css.cart ?? DEFAULT_STYLES.cart);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        signals?.focus.value.replace(data.signals.cart.value[data.shop.token]?.length ? 'cart-entry-0' : 'noFocus');
        setTotal(totalPrice);
    }, []);
    useEffect(() => {
        setTotal(totalPrice);
    }, [data.signals?.cart?.value?.[data.shop.token]?.length]);
    useEffect(() => {
        sessionStorage.setItem("orderTotal", String(limitDecimals(total)));
    }, [total]);

    return (
        <>
            <Main data={data} stylesheet={stylesheet} injected={injected} templating={templating} total={total} />
            <Footer data={data} stylesheet={stylesheet} injected={injected} templating={templating} />
        </>
    );
}) as Layouts.Cart;